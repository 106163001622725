<template>
  <div>
    События и аварии
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EventsAndIncidents',
  computed: {
    ...mapGetters('identity', ['isAuthenticated', 'token']),
  },
};
</script>

<style scoped>

</style>
